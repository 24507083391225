<template>
  <div class="page flex-col">
    <div class="box_37 flex-col">
      <div class="page-title">
      <span class="text_1">钉钉知识导航/组织数字化解决方案</span>
      <a href="https://www.dingtalk.com/"  target="_blank" rel="noopener noreferrer">
      <img class="d_image" src="@/assets/image/ddLogo.png" alt="">
      </a>
      </div>
      <div class="section_8 flex-row justify-between">
        <div class="text-wrapper_1 flex-col">
          <span class="text_2">钉钉生态能力</span>
        </div>
        <div class="group_17 flex-col">
          <div class="block_6 flex-row">
            <div class="text-wrapper_2 flex-col">
              <span class="text_3">解决方案</span>
            </div>
            <div class="box_38 flex-col">
              <a href="https://appcenter.dingtalk.com/" target="_blank" rel="noopener noreferrer">
              <div class="group_18 flex-row justify-between">
                <div class="image-wrapper_1 flex-col">
                  <img
                    class="label_1"
                    referrerpolicy="no-referrer"
                    src="@/assets/image/1.svg"
                  />
                </div>
                <span class="text_4">Saas标准化应用</span>
              </div>
              <span class="text_5">标准化产品、上线周期短</span>
              <span class="paragraph_1"
                >适应情况：无个性化要求的；难以定制开<br />发的或开发成本非常高的</span
              >
              </a>
            </div>
            <div class="box_39 flex-col">
              <a href="https://www.aliwork.com/" target="_blank" rel="noopener noreferrer">
              <div class="block_7 flex-row justify-between">
                <img
                  class="image_1"
                  referrerpolicy="no-referrer"
                  src="@/assets/image/2.svg"
                />
                <span class="text_6">低代码开发平台（钉钉搭）</span>
              </div>
              <div class="block_8 flex-row justify-between">
                <div class="box_3 flex-col"></div>
                <span class="text_7">堆积木的方式搭建企业自己的管理应用</span>
              </div>
              <div class="block_9 flex-row justify-between">
                <div class="group_6 flex-col"></div>
                <span class="text_8">让懂业务不会代码的人员也能开发系统</span>
              </div>
              <div class="block_10 flex-row justify-between">
                <div class="section_3 flex-col"></div>
                <span class="text_9">让管理流程和制度高效、快速地落地</span>
              </div>
              </a>
            </div>
            <div class="box_40 flex-col justify-between">
              <a href="" target="_blank" rel="noopener noreferrer">
              <div class="block_11 flex-row justify-between">
                <img
                  class="image_2"
                  referrerpolicy="no-referrer"
                  src="@/assets/image/3.svg"
                />
                <span class="text_10">BI数据分析</span>
              </div>
              <span class="paragraph_2"
                >连接多平台/多应用数据源，通<br />过拖拽的方式，快速配置数据分<br />析报表</span
              >
              </a>
            </div>
            <div class="box_41 flex-col justify-between">
              <a href="https://open.dingtalk.com/" target="_blank" rel="noopener noreferrer">
              <div class="box_42 flex-row justify-between">
                <img
                  class="image_3"
                  referrerpolicy="no-referrer"
                  src="@/assets/image/4.svg"
                />
                <span class="text_11">系统集成</span>
              </div>
              </a>
              <div class="image-wrapper_7 flex-row justify-between">
                <img
                  class="label_2"
                  referrerpolicy="no-referrer"
                  src="@/assets/image/addw.png"
                />
                <a href="http://www.nuojiecloud.com/" target="_blank" rel="noopener noreferrer">
                <img
                  class="image_4"
                  referrerpolicy="no-referrer"
                  src="@/assets/image/mesLogo.png"
                />
                </a>
              </div>
              <span class="paragraph_3"
                >ERP、MES、自建应用等系统的<br />集成</span
              >
            </div>
            <span class="text_12">让系统集成更简单</span>
          </div>
          <div class="border"></div>
          <div class="block_12 flex-row">
            <div class="text-wrapper_3 flex-col">
              <span class="text_13">应用场景</span>
            </div>
            <div class="yy_left">
            <div class="group_19 flex-col">
              <div class="text-wrapper_4 flex-col">
                <span class="text_14">业务管理</span>
              </div>
              <div class="box_43 flex-row justify-between">
                <div class="text-wrapper_5 flex-col">
                  <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4gLQYv533z8e" target="_blank" >销售管理</a>
                </div>
                <div class="text-wrapper_5 flex-col">
                  <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4g2OdWbO3z8e" target="_blank" >费控管理</a>
                </div>
                <div class="text-wrapper_5 flex-col">
                  <a href="https://www.teambition.com/" target="_blank" >项目管理</a>
                </div>
                <div class="text-wrapper_5 flex-col">
                  <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4g2D4o3jRz8e" target="_blank" >电子签章</a>
                </div>
                <div class="text-wrapper_5 flex-col">
                  <a href="" target="_blank" >工程管理</a>
                </div>
              </div>
            </div>
            <div class="group_19 flex-col">
              <div class="text-wrapper_4 flex-col">
                <span class="text_14">人事一体化管理</span>
              </div>
              <div class="box_43 flex-row justify-between">
                <div class="text-wrapper_5 flex-col">
                  <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4g07kY7PEz8e" target="_blank" >招聘管理</a>
                </div>
                <div class="text-wrapper_5 flex-col">
                  <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4g2ej2Vqlz8e" target="_blank" >培训管理</a>
                </div>
                <div class="text-wrapper_5 flex-col">
                  <a href="" target="_blank" >员工关系</a>
                </div>
                <div class="text-wrapper_5 flex-col">
                  <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4gLdMR1rlz8e" target="_blank" >绩效管理</a>
                </div>
                <div class="text-wrapper_5 flex-col">
                  <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4gL95od56z8e" target="_blank" >薪酬管理</a>
                </div>
                <div class="text-wrapper_5 flex-col">
                  <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4g20Wo3wEz8e" target="_blank" >高级排班</a>
                </div>
              </div>
            </div>
            <div class="group_19 flex-col">
              <div class="text-wrapper_4 flex-col">
                <span class="text_14">行政管理</span>
              </div>
              <div class="box_43 flex-row justify-between">
                <div class="text-wrapper_5 flex-col">
                  <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4gLV8w8Nwz8e" target="_blank" >物品管理</a>
                </div>
                <div class="text-wrapper_5 flex-col">
                  <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4g2N1lV4Pz8e" target="_blank" >宿舍管理</a>
                </div>
                <div class="text-wrapper_5 flex-col">
                  <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4g2Rv1yb6z8e" target="_blank" >车辆管理</a>
                </div>
                <div class="text-wrapper_5 flex-col">
                  <a href="" target="_blank" >物品报修</a>
                </div>
                <div class="text-wrapper_5 flex-col">
                  <a href="" target="_blank" >访客管理</a>
                </div>
                <div class="text-wrapper_5 flex-col">
                  <a href="" target="_blank" >巡查管理</a>
                </div>
              </div>
            </div>
            <div class="group_19 flex-col">
              <div class="text-wrapper_4 flex-col">
                <span class="text_14">生产管理</span>
              </div>
              <div class="box_43 flex-row justify-between">
                <div class="text-wrapper_5 flex-col">
                  <a href="" target="_blank" >工单管理</a>
                </div>
                <div class="text-wrapper_5 flex-col">
                  <a href="" target="_blank" >设备管理</a>
                </div>
                <div class="text-wrapper_5 flex-col">
                  <a href="" target="_blank" >计件工资</a>
                </div>
                <div class="text-wrapper_5 flex-col">
                  <a href="" target="_blank" >量具管理</a>
                </div>
                <img
                class="image_6"
                referrerpolicy="no-referrer"
                src="@/assets/image/slh.png"
              />
              </div>
            </div>

            </div>
            <div class="image-wrapper_8 flex-col justify-between">
              <img
                class="image_7"
                referrerpolicy="no-referrer"
                src="@/assets/image/slh.png"
              />
              <img
                class="label_3"
                referrerpolicy="no-referrer"
                src="@/assets/image/addw.png"
              />
            </div>
            <div class="text-wrapper_28 flex-col">
              <span class="text_38">钉钉智连</span>
            </div>
            <div class="image-wrapper_9 flex-col justify-between">
              <img
                class="image_8"
                referrerpolicy="no-referrer"
                src="@/assets/image/zl1.png"
              />
              <img
                class="image_9"
                referrerpolicy="no-referrer"
                src="@/assets/image/zl4.png"
              />
            </div>
            <div class="image-wrapper_10 flex-col justify-between">
              <img
                class="image_10"
                referrerpolicy="no-referrer"
                src="@/assets/image/zl2.png"
              />
              <img
                class="image_11"
                referrerpolicy="no-referrer"
                src="@/assets/image/zl5.png"
              />
            </div>
            <div class="image-wrapper_11 flex-col justify-between">
              <img
                class="image_12"
                referrerpolicy="no-referrer"
                src="@/assets/image/zl3.png"
              />
              <img
                class="image_13"
                referrerpolicy="no-referrer"
                src="@/assets/image/zl6.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="section_13 flex-row justify-between">
        <div class="text-wrapper_29 flex-col">
          <span class="text_39">协同办公平台底座</span>
        </div>
        <div class="block_13 flex-col">
          <div class="box_10 flex-row">
            <span class="text_40">钉钉开放平台</span>
            <div class="block_14 flex-row justify-between">
              <div class="text-wrapper_30 flex-col">
                <span class="text_41">组织接入</span>
              </div>
              <div class="text-wrapper_31 flex-col">
                <span class="text_42">单点登录</span>
              </div>
              <div class="text-wrapper_32 flex-col">
                <span class="text_43">消息集成</span>
              </div>
              <div class="text-wrapper_33 flex-col">
                <span class="text_44">数据/流程集成</span>
              </div>
              <div class="text-wrapper_34 flex-col">
                <span class="text_45">待办集成</span>
              </div>
              <div class="text-wrapper_35 flex-col">
                <span class="text_46">门户集成</span>
              </div>
            </div>
          </div>
          <div class="group_26 flex-row">
            <div class="text-wrapper_36 flex-col">
              <span class="text_47">钉钉专属版</span>
            </div>
            <div class="text-wrapper_37 flex-col">
              <span class="text_48">专属钉基础版</span>
            </div>
            <div class="group_27 flex-col justify-between">
              <div class="text-wrapper_38 flex-col">
                <a href="" target="_blank" class="text_49">专属设计</a>
              </div>
              <div class="text-wrapper_39 flex-col">
                <a href="" target="_blank" class="text_50">专属账号</a>
              </div>
              <div class="text-wrapper_40 flex-col">
                <a href="" target="_blank" class="text_51">互动服务窗</a>
              </div>
            </div>
            <div class="group_28 flex-col">
              <div class="text-wrapper_41 flex-col">
                <a href="" target="_blank" class="text_52">关联组织</a>
              </div>
              <div class="text-wrapper_42 flex-col">
                <a href="" target="_blank" class="text_53">专属开放</a>
              </div>
              <div class="text-wrapper_43 flex-col">
                <a href="" target="_blank" class="text_54">一个万人群</a>
              </div>
            </div>
            <div class="group_29 flex-col justify-between">
              <div class="text-wrapper_44 flex-col">
                <a href="" target="_blank" class="text_55">专业版能力</a>
              </div>
              <div class="text-wrapper_45 flex-col">
                <a class="text_56">专属服务</a>
              </div>
            </div>
            <img
              class="label_4"
              referrerpolicy="no-referrer"
              src="@/assets/image/addw.png"
            />
            <div class="text-wrapper_46 flex-col">
              <span class="text_57">专属钉可选模块</span>
            </div>
            <div class="group_30 flex-col">
              <div class="text-wrapper_47 flex-col">
                <a href="" target="_blank" class="text_58">专属安全</a>
              </div>
              <div class="text-wrapper_48 flex-col">
                <a href="" target="_blank" class="text_59">三色管控</a>
              </div>
              <div class="text-wrapper_49 flex-col">
                <a href="" target="_blank" class="text_60">专属存储</a>
              </div>
            </div>
            <div class="group_31 flex-col justify-between">
              <div class="text-wrapper_50 flex-col">
                <a href="" target="_blank" class="text_61">专属APP</a>
              </div>
              <div class="text-wrapper_51 flex-col">
                <a href="" target="_blank" class="text_62">专属审计</a>
              </div>
            </div>
            <img
              class="label_5"
              referrerpolicy="no-referrer"
              src="@/assets/image/addw.png"
            />
            <div class="text-wrapper_52 flex-col">
              <span class="text_63">专属钉解决方案</span>
            </div>
            <div class="group_32 flex-col justify-between">
              <div class="text-wrapper_53 flex-col">
                <a href="" target="_blank" class="text_64">音视频混合云部署</a>
              </div>
              <div class="text-wrapper_54 flex-col">
                <a href="" target="_blank" class="text_65">内网直播加速方案</a>
              </div>
              <div class="text-wrapper_55 flex-col">
                <a href="" target="_blank" class="text_66">内网应用安全网关</a>
              </div>
            </div>
            <div class="group_33 flex-col justify-between">
              <div class="text-wrapper_56 flex-col">
                <a href="" target="_blank" class="text_67">专属网络服务</a>
              </div>
              <div class="text-wrapper_57 flex-col">
                <a href="" target="_blank" class="text_68">企业原有账号整合</a>
              </div>
              <div class="text-wrapper_58 flex-col">
                <a href="" target="_blank" class="text_69">第三方加密解决方案</a>
              </div>
            </div>
            <div class="group_34 flex-col justify-between">
              <div class="text-wrapper_59 flex-col">
                <a href="" target="_blank" class="text_70">会议重保服务</a>
              </div>
              <div class="text-wrapper_60 flex-col">
                <a href="" target="_blank" class="text_71">业务系统集成方案</a>
              </div>
            </div>
          </div>
          <div class="border"></div>
          <div class="group_35 flex-row">
            <div class="text-wrapper_61 flex-col">
              <span class="text_72">钉钉专业版</span>
            </div>
            <div class="group_36 flex-col justify-between">
              <div class="text-wrapper_62 flex-col">
                <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4g06ejngRz8e" target="_blank" class="text_73">尊享标识</a>
              </div>
              <div class="text-wrapper_63 flex-col">
                <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4gNAvQMA2z8e" target="_blank" class="text_74">高管降噪</a>
              </div>
            </div>
            <div class="group_37 flex-col justify-between">
              <div class="text-wrapper_64 flex-col">
                <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4g00V20JRz8e" target="_blank" class="text_75">保密群</a>
              </div>
              <div class="text-wrapper_65 flex-col">
                <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4g0vLOAjOz8e" target="_blank" class="text_76">聊天记录延长</a>
              </div>
              <div class="text-wrapper_66 flex-col">
                <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4g0AW5ZZRz8e" target="_blank" class="text_77">视频会议专业版</a>
              </div>
              <div class="text-wrapper_64 flex-col" style="margin-top: 6px;">
                <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4gJrYkr0Oz8e" target="_blank" class="text_75">连接器</a>
              </div>
            </div>
            <div class="group_38 flex-col justify-between">
              <div class="text-wrapper_67 flex-col">
                <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4g2e8x9nRz8e" target="_blank" class="text_78">考勤专业版</a>
              </div>
              <div class="text-wrapper_68 flex-col">
                <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4gNNo7kD6z8e" target="_blank" class="text_79">审批专业版</a>
              </div>
              <div class="text-wrapper_69 flex-col">
                <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4grxvoLLlz8e" target="_blank" class="text_80">云盘1T存储空间</a>
              </div>
              <div class="text-wrapper_70 flex-col">
                <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4g0WbLx0Pz8e" target="_blank" class="text_81">云盘日志可查询</a>
              </div>
            </div>
            <div class="group_39 flex-col justify-between">
              <div class="text-wrapper_71 flex-col">
                <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4g2OLDa56z8e" target="_blank" class="text_82">自定义工作台</a>
              </div>
              <div class="text-wrapper_72 flex-col">
                <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4gQjWnPylz8e" target="_blank" class="text_83">数据恢复</a>
              </div>
              <div class="text-wrapper_72_1 flex-col">
                <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4g2a4jWa6z8e" target="_blank" class="text_83">文档&多维表权益</a>
              </div>
              <div class="text-wrapper_72_1 flex-col">
                <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4gKp8PPN9z8e" target="_blank" class="text_83">企业数据洞察中心</a>
              </div>
            </div>
            <img
              class="label_6"
              referrerpolicy="no-referrer"
              src="@/assets/image/addw.png"
            />
            <div class="text-wrapper_73 flex-col">
              <span class="text_84">更多权益</span>
            </div>
            <span class="paragraph_4"
              >7*10人工在线服务<br />指定应用1000元抵用券（满6800<br />元）<br />1000条电话/短信DING<br />5000分钟电话会议</span
            >
            <img
              class="label_7"
              referrerpolicy="no-referrer"
              src="@/assets/image/addw.png"
            />
            <div class="text-wrapper_74 flex-col">
              <span class="text_85">专属支持</span>
            </div>
            <div class="group_40 flex-col">
              <span class="text_86">专属支持服务，限时、限量赠送</span>
              <div class="block_15 flex-row justify-between">
                <div class="box_27 flex-col"></div>
                <span class="text_87">专属服务群、专属客服支持</span>
              </div>
              <div class="block_16 flex-row justify-between">
                <div class="group_14 flex-col"></div>
                <span class="text_88"
                  >数字化管理咨询、梳理、规划、方案设计</span
                >
              </div>
              <div class="block_17 flex-row justify-between">
                <div class="group_15 flex-col"></div>
                <span class="text_89">线上、电话、远程支持</span>
              </div>
              <div class="block_18 flex-row justify-between">
                <div class="group_16 flex-col"></div>
                <span class="paragraph_5"
                  >响应时间：工作日9:00&nbsp;—&nbsp;18:00，休息日、节假<br />日保持电话畅通</span
                >
              </div>
            </div>
          </div>
          <div class="border"></div>
          <div class="group_41 flex-row">
            <div class="text-wrapper_75 flex-col">
              <span class="text_90">钉钉基础版</span>
            </div>
            <div class="box_45 flex-col justify-between">
              <div class="block_19 flex-row justify-between">
                <div class="text-wrapper_76 flex-col">
                  <span class="text_91">组织架构</span>
                </div>
                <div class="text-wrapper_77 flex-col">
                  <span class="text_92">企业级沟通</span>
                </div>
                <div class="text-wrapper_78 flex-col">
                  <span class="text_93">协同办公</span>
                </div>
              </div>
              <div class="block_20 flex-row">
                <div class="image-text_10 flex-col justify-between">
                  <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4E9729EEEm8e" target="_blank" rel="noopener noreferrer">
                  <img
                    class="label_8"
                    referrerpolicy="no-referrer"
                    src="@/assets/image/txl.svg"
                  />
                  <span class="text-group_1">通讯录</span>
                  </a>
                </div>
                <div class="image-text_11 flex-col justify-between">
                  <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4Evv51QP3m8e" target="_blank" rel="noopener noreferrer">
                  <img
                    class="label_9"
                    referrerpolicy="no-referrer"
                    src="@/assets/image/jstx.svg"
                  />
                  <span class="text-group_2">即时通讯</span>
                  </a>
                </div>
                <div class="image-text_12 flex-col justify-between">
                  <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4g0y9rZ2oz8e" target="_blank" rel="noopener noreferrer">
                  <img
                    class="label_10"
                    referrerpolicy="no-referrer"
                    src="@/assets/image/sphy.svg"
                  />
                  <span class="text-group_3">视频会议</span>
                  </a>
                </div>
                <div class="image-text_13 flex-col justify-between">
                  <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4gNE7DwLlz8e" target="_blank" rel="noopener noreferrer">
                  <img
                    class="label_11"
                    referrerpolicy="no-referrer"
                    src="@/assets/image/qzb.svg"
                  />
                  <span class="text-group_4">群直播</span>
                  </a>
                </div>
                <div class="image-text_14 flex-col justify-between">
                  <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4gQ1DDMYlz8e" target="_blank" rel="noopener noreferrer">
                  <img
                    class="label_12"
                    referrerpolicy="no-referrer"
                    src="@/assets/image/sp.svg"
                  />
                  <span class="text-group_5">审批基础版</span>
                  </a>
                </div>
                <div class="image-text_15 flex-col justify-between">
                  <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4gQLeQnPoz8e" target="_blank" rel="noopener noreferrer">
                  <img
                    class="label_13"
                    referrerpolicy="no-referrer"
                    src="@/assets/image/rz.svg"
                  />
                  <span class="text-group_6">日志</span>
                  </a>
                </div>
                <div class="image-text_16 flex-col justify-between">
                  <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4gN6lRgx9z8e" target="_blank" rel="noopener noreferrer">
                  <img
                    class="label_14"
                    referrerpolicy="no-referrer"
                    src="@/assets/image/dsh.svg"
                  />
                  <span class="text-group_7">钉闪会</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="box_46 flex-col justify-between">
              <div class="text-wrapper_79 flex-col">
                <span class="text_94">基础人事管理</span>
              </div>
              <div class="block_21 flex-row justify-between">
                <div class="image-text_17 flex-col justify-between">
                  <a href="" target="_blank" rel="noopener noreferrer">
                  <img
                    class="label_15"
                    referrerpolicy="no-referrer"
                    src="@/assets/image/yggx.svg"
                  />
                  <span class="text-group_8">员工关系</span>
                  </a>
                </div>
                <div class="image-text_18 flex-col justify-between">
                  <a href="https://alidocs.dingtalk.com/i/p/Qrkoz3pKL7jjX6L7dBgX4g0AEvb2oz8e" target="_blank" rel="noopener noreferrer">
                  <img
                    class="label_16"
                    referrerpolicy="no-referrer"
                    src="@/assets/image/kq.svg"
                  />
                  <span class="text-group_9">考勤基础版</span>
                  </a>
                </div>
              </div>
            </div>
            <img
              class="label_17"
              referrerpolicy="no-referrer"
              src="@/assets/image/addb.png"
            />
            <div class="text-wrapper_80 flex-col">
              <span class="text_95">基础服务</span>
            </div>
            <div class="text-wrapper_82 flex-col">
              <span class="text_96">服务范围：</span>
              <span class="paragraph_6"
                >系统使用上明确的问题答疑。不含：梳理、规划、解决方案<br />咨询、实施、培训等服务</span
              >
              <span class="text_97">服务方式：</span>
              <span class="paragraph_7"
                >线上机器人/人工客服（非专属、需排队，长时间未回应自动<br />关闭）</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
    };
  },
  methods: {},
};
</script>
<style src="../src/views/common.css" />
<style src="../src/views/index.css" />
